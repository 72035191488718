<template>
  <div class="w-full felx">
    <div class="mt-4 text-left text-gray-500 path">
      <navbar-text :navbarList="navbarList"></navbar-text>
    </div>
    <div class="mt-2">
      <span class="font-bold">自主服务保修</span>
      <span class="text-gray-400 ">（提交之后可在个人中心-保修查询查看）</span>
    </div>
    <div class="flex justify-center">
      <div class="mt-4">
        <el-form ref="form" label-width="100px" label-position="left" v-for="item in dataList" :key="item.id">
          <el-form-item :label="item.name">
            <div v-if="item.fieldName == 'modelId'">
              <div class="pc_select_show">
                <el-select v-model="item.value" filterable placeholder="请选择" style="width:100%;max-width: 21.875rem;">
                  <el-option v-for="modle in modles" :key="modle.value" :label="modle.label"
                    :value="modle.value"></el-option>
                </el-select>
              </div>
              <div class="phone_select_show">
                <div class="flex form_input custom_input" @click="openPopup('mobilepopup')" style="width:100%">
                  <span :class="item.value == '' ? 'text-gray-300' : ''">
                    {{
        item.value == ""
          ? "请选择"
          : transformSelect(modles, item.value)
      }}
                  </span>
                </div>
              </div>
            </div>
            <div v-else-if="item.fieldName == 'goodsName'">
              <div class="pc_select_show">
                <el-select v-model="item.value" filterable placeholder="请选择" style="width:100%;max-width: 21.875rem;">
                  <el-option v-for="(modle, index) in productList" :key="index" :label="modle.goodsName"
                    :value="modle.goodsNo"></el-option>
                </el-select>
              </div>
              <div class="phone_select_show">
                <div class="flex form_input custom_input" @click="openPopup('productpopup')" style="width:100%">
                  <span :class="item.value == '' ? 'text-gray-300' : ''">
                    {{
        item.value == ""
          ? "请选择"
          : transformSelect(productList, item.value, 'label')
      }}
                  </span>
                </div>
              </div>
            </div>
            <div v-else-if="item.fieldName == 'shopName'">
              <div class="pc_select_show">
                <el-select filterable v-model="item.value" placeholder="请选择" style="width:100%;max-width: 21.875rem;">
                  <el-option v-for="(modle, index) in salesList" :key="index" :label="modle.channelName"
                    :value="modle.channelName"></el-option>
                </el-select>
              </div>
              <div class="phone_select_show">
                <div class="flex form_input custom_input" @click="openPopup('salespopup')" style="width:100%">
                  <span :class="item.value == '' ? 'text-gray-300' : ''">
                    {{
        item.value == ""
          ? "请选择"
          : transformSelect(salesList, item.value)
      }}
                  </span>
                </div>
              </div>
            </div>
            <div v-else-if="item.fieldName == 'address'">
              <div class="pc_select_show">
                <el-cascader ref="myCascader" size="large" :options="regionData" v-model="selectedOptions"
                  @change="handleChange" class="form_input" style="width: 100%;max-width: 21.875rem;"></el-cascader>
              </div>
              <div class="phone_select_show">
                <div class="flex form_input custom_input" @click="drawer = true">
                  <span :class="selectAddress == '' ? 'text-gray-300' : ''">{{ selectAddress == '' ? '请选择地址' :
        selectAddress
                    }}</span>
                </div>
              </div>
              <el-input v-model="detailAddress" placeholder="请填写详细地址" class="mt-3 input_box"></el-input>
              <div class="flex justify-center" v-if="warrantyId">
                <div class="p-1 px-2 mt-3 text-left bg-gray-100 rounded" style="width:100%;max-width: 21.875rem;">
                  <div>{{ item.value }}</div>
                  <div class="text-xs text-primary">（如需修改请重新选择收货地址，并填写详细地址）</div>
                </div>
              </div>
            </div>
            <el-input v-model="item.value"
              :placeholder="item.fieldName == 'serialNum' ? 'LOT或SZ开头的条码编号' : item.fieldName == 'orderNum' ? '购买店铺的订单' : '请填写' + item.name"
              v-else-if="item.type == 1 && item.fieldName != 'modelId' && item.fieldName != 'address' && item.fieldName != 'mailingList' && item.fieldName != 'goodsName' && item.fieldName != 'shopName'"
              class="input_box">
            </el-input>
            <el-input type="textarea" v-model="item.value" placeholder="尽量把问题描述清楚，字数不要超过300字"
              v-else-if="item.type == 3 && item.fieldName != 'modelId' && item.fieldName != 'address' && item.fieldName != 'mailingList' && item.fieldName != 'goodsName' && item.fieldName != 'shopName'"
              class="input_box"></el-input>
            <div v-else-if="item.fieldName == 'mailingList'">
              <el-checkbox-group v-model="item.value" class="flex flex-wrap">
                <el-checkbox label="电脑整机" name="type"></el-checkbox>
                <el-checkbox label="适配器" name="type"></el-checkbox>
                <el-checkbox label="电池" name="type"></el-checkbox>
                <div style="width:150px;opcity:0"></div>
              </el-checkbox-group>
            </div>

            <div v-else>
              <div>
                <div class="relative el-upload el-upload--picture-card" v-for="(uploadImg, index) in item.uploadList"
                  :key="uploadImg" style="margin-bottom:10px">
                  <img :src="uploadImg" alt="" style="width: 100%;height: 100%;"
                    @click="handlePictureCardPreview(uploadImg)" />
                  <img src="../../../../assets/images/common/delete.png" alt="" class="absolute"
                    style="top: 2px;right:2px;width: 25px;height: auto;" @click="deleteImg(item, index)" />
                </div>
                <el-upload :action="qiniuUrl" list-type="picture-card"
                  :on-preview="(file) => { handlePictureCardPreview(file.url); }"
                  :on-remove="(file, fileList) => { handleRemove(file, fileList, item); }" :limit="5"
                  :auto-upload="false" :on-success="(res) => { uploadSuccess(res, item); }" accept=".png, .jpg, .jpeg"
                  :data="uploadData" :ref="item.fieldName" :multiple="true">
                  <i class="el-icon-plus"></i>
                </el-upload>
              </div>

              <div class="pt-1 text-xs text-gray-600">
                点击虚框选择图片，选择后请点击上传
              </div>
              <el-button style="margin-top: 10px;" size="small" type="primary" @click="submitUpload(item)">确认上传
              </el-button>
            </div>

          </el-form-item>
        </el-form>
        <div class="flex items-center justify-center">
          <div class="flex items-center">
            <!-- <el-checkbox-group v-model="form.list">
                            <el-checkbox label name="type"></el-checkbox>
                        </el-checkbox-group>-->
            <span style="height:.875rem;width: .875rem;border:.0625rem solid #f56c6c;box-sizing: border-box; "
              class="flex items-center justify-center mr-1 rounded cursor-pointer" @click="agress = !agress">
              <span style="height:.5rem;width: .5rem;display: inline-block;border-radius: .125rem;" class="bg-primary"
                v-if="agress"></span>
            </span>
            <div class="pl-1">
              我已阅读并接受
              <span class="cursor-pointer text-primary" @click="goAgreement(1)">用户协议</span>和
              <span class="cursor-pointer text-primary" @click="goAgreement(2)">隐私政策</span>
            </div>
          </div>
        </div>
        <div class="mt-2 mb-6">
          <el-button style="margin-top: 10px;width:50%;" size="small" type="primary" @click="applyCheckRules">
            提交</el-button>
        </div>
      </div>
    </div>

    <!-- 图片预览 -->
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" fit="contain" :src="dialogImageUrl" alt />
    </el-dialog>
    <MobilePopup ref="mobilepopup" :list="modles" @select="selectFunc"></MobilePopup>
    <MobilePopup ref="salespopup" :list="salesList" @select="selectSalesFunc"></MobilePopup>
    <MobilePopup ref="productpopup" :list="productList" @select="selectProductFunc"></MobilePopup>
    <!-- moblie 地址选择 -->
    <el-drawer title :visible.sync="drawer" :direction="direction" :before-close="handleClose" :show-close="false"
      :withHeader="false" size="50%">
      <div style="height: 50vh;width: 100vw;">
        <v-distpicker type="mobile" @selected="selected"></v-distpicker>
      </div>
    </el-drawer>
    <Popup :isShow="isShow" @close="closePopup" :width="isMobile ? '90vw' : popupWidth">
      <template>
        <div class="relative px-4">
          <div class="absolute top-0 right-0 cursor-pointer" @click="closePopup">
            <img src="../../../../assets/images/common/close.png" alt="" style="width: 30px;" />
          </div>
          <div class="py-3 text-center text-gray-700 md:text-xl">提示</div>
          <div class="my-3 text-sm text-center text-gray-500">
            你修改了收货地址，如确定修改，请填写好详细地址
          </div>
          <div class="flex items-center justify-between pb-2">
            <div class="py-1 text-white bg-gray-400 cursor-pointer serve_btn" @click="serverClick(1)">
              不修改
            </div>
            <div class="py-1 text-white cursor-pointer bg-primary serve_btn" @click="serverClick(2)">
              确定修改
            </div>
          </div>
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { USER, PRIVACY } from "../../../../common/constant";
import { regionData } from "element-china-area-data";
import Popup from "@/components/Popup/Popup";
export default {
  data() {
    return {
      regionData: regionData,
      selectedOptions: [],
      drawer: false,
      direction: "btt",
      selectAddress: "", // 选择的地址
      detailAddress: "", //填写的详细地址
      // 图片上传的相关属性
      uploadData: {
        token: "",
      },
      uploadImages: [],
      uploadImages1: [],
      dialogVisible: false,
      dialogImageUrl: "",
      agreement: {
        1: USER,
        2: PRIVACY,
      },

      agress: false,
      dataList: [],
      target: {}, // 存储图片上传时的目标对象
      modles: [],
      warrantyId: "",
      navbarList: [{ title: "售后服务", path: "" }],
      popupWidth: "23rem",
      isShow: false,
      notModifyAddress: false, // 不修改
      loading: false,
      productList: [],
      salesList: [],
    };
  },
  components: {
    Popup,
  },
  computed: {
    ...mapGetters(["getQiniuToken", "getQinniuUrl"]),
    ...mapState(["isMobile", "userInfo"]),
  },
  created() {
    this.warrantyId = this.$route.query.warrantyId;
    // console.log(this.warrantyId)
    this.getDropDownBoxData();
    this.getProductList()
    this.getSalesList()
    // 获取七牛token
    this.$store.dispatch("getAccessToken", this.$EventBus);
    this.uploadData.token = this.getQiniuToken;
    this.getRepairFrom();
  },
  watch: {
    getQiniuToken: function () {
      this.uploadData.token = this.getQiniuToken;
    },
  },
  methods: {
    getProductList() {
      this.$request({
        method: "get",
        url: this.$requestPath.productList,
        data: {},
      }).then(res => {
        // console.log("获取产品列表", res)
        if (res.code == 0) {
          this.productList = []
          res.data.forEach(item => {
            item.label = item.goodsName
            item.value = item.goodsNo
          })
          this.productList.push(...res.data)
        } else {
          this.$EventBus.$emit("toast", { type: "error", message: res.msg });
        }
      })
    },
    getSalesList() {
      this.$request({
        method: "get",
        url: this.$requestPath.salesList,
        data: {},
      }).then(res => {
        // console.log("获取销售渠道", res)
        if (res.code == 0) {
          this.salesList = []
          res.data.forEach(item => {
            item.label = item.channelName
            item.value = item.channelName
          })
          this.salesList.push(...res.data)
        } else {
          this.$EventBus.$emit("toast", { type: "error", message: res.msg });
        }
      })
    },
    // 关闭提示弹窗
    closePopup() {
      this.isShow = false;
    },
    // 提示弹窗按钮点击
    serverClick(type) {
      this.closePopup();
      if (type == 1) {
        this.notModifyAddress = true
      } else {
        this.notModifyAddress = false
      }
    },
    /**
     * @description: 选择地址（移动端）
     * @param {*} value
     * @return {*}
     */
    handleChange(value) {
      // console.log(this.$refs.myCascader[0].getCheckedNodes()[0].pathLabels.join(""))
      this.addressCode = [];
      this.addressCode.push(...value);
      this.selectAddress = this.$refs.myCascader[0].getCheckedNodes()[0].pathLabels.join("")
    },
    handleClose(done) {
      done();
    },
    /**
     * @description: 选择地址（移动端）
     * @param {*} e
     * @return {*}
     */
    selected(e) {
      console.log(e);
      this.selectAddress =
        e.province.value + "/" + e.city.value + "/" + e.area.value;
      this.addressCode = [];
      this.addressCode = [e.province.code, e.city.code, e.area.code];
      this.selectedOptions = [];
      this.selectedOptions = [e.province.code, e.city.code, e.area.code];
      this.drawer = false;
    },
    // 移除已上传图片
    deleteImg(item, index) {
      let uploadList = [...item.uploadList];
      uploadList.splice(index, 1);
      item.uploadList = [];
      item.uploadList.push(...uploadList);
    },
    // 获取设备物流表单
    getRepairFrom() {
      this.$store.dispatch("order/getRepairFrom", (data) => {
        this.dataList = [];
        this.dataList.push(...data);
        let index = this.dataList.findIndex(item => {
          return item.fieldName == 'mailingList'
        })
        index ? this.dataList[index].value = [] : ""
      });
      if (this.warrantyId) {
        this.getDetail()
      }
    },
    /**
     * @description: 获取维修单详情
     * @return {*}
     */
    getDetail() {
      this.$store.dispatch("order/getRepairInfo", {
        warrantyId: this.warrantyId,
        callback: (data) => {
          if (data.custom) {
            data.custom = JSON.parse(data.custom);
            Object.keys(data.custom).forEach((key) => {
              data[key] = data.custom[key];
            });
          }
          this.dataList.forEach((item) => {
            if (item.type == 2) {
              item.uploadList = data[item.fieldName];
            } else if (item.fieldName == 'mailingList') {
              item.value = data[item.fieldName] ? data[item.fieldName].split(",") : []
            } else {
              item.value = data[item.fieldName];
            }
          });
          // console.log(this.dataList)
        },
      });
    },
    openPopup(ref) {
      this.$refs[ref].open();
    },
    // 转换（移动端需要根据value值显示对应的结果）
    transformSelect(list, value, key = 'value') {
      let result = list.filter((item) => {
        return value == item[key];
      });
      return result.length > 0 ? result[0].label : "";
    },
    // 获取保修单下拉框数据
    getDropDownBoxData() {
      this.$request({
        method: "get",
        url: this.$requestPath.getDropDownBoxData,
        data: {},
      })
        .then((res) => {
          // console.log("获取保修单下拉数据", res);
          let { modles } = res.data;
          this.modles = [];
          if (modles.length > 0) {
            modles[0].driveCategoryDtos.forEach((item) => {
              item.driveCategoryDtos.forEach((children) => {
                children.value = children.driveCategoryId;
                children.label = children.categoryName;
                this.modles.push(children);
              });
            });
          }
        })
        .catch((error) => {
          console.log("获取保修单下拉数据", error);
        });
    },
    selectFunc(target) {
      let index = this.dataList.findIndex((item) => {
        return item.fieldName == "modelId";
      });
      if (index > -1) {
        this.dataList[index].value = target.value + "";
      }
    },
    selectSalesFunc(target) {
      let index = this.dataList.findIndex((item) => {
        return item.fieldName == "shopName";
      });
      if (index > -1) {
        this.dataList[index].value = target.value + "";
      }
    },
    selectProductFunc(target) {
      console.log(target)
      let index = this.dataList.findIndex((item) => {
        return item.fieldName == "goodsName";
      });
      if (index > -1) {
        this.dataList[index].value = target.label + "";
      }
    },
    // 移除图片
    handleRemove(file, fileList, target) {
      // console.log(file, fileList, target);
      if (file.response) {
        let list = target.list.filter((item) => {
          return item.key != file.response.key;
        });
        target.list = [];
        target.list.push(...list);
      }
    },
    // 查看图片
    handlePictureCardPreview(url) {
      this.dialogImageUrl = url;
      this.dialogVisible = true;
    },
    // 图片上传
    submitUpload(item) {
      this.$refs[item.fieldName][0].submit();
    },
    // 上传成功
    uploadSuccess(res, target) {
      // this.getQinniuUrl
      target.list.push(res);
      // console.log(target.list);
    },

    // 申请校验
    applyCheckRules() {
      console.log(this.dataList)
      let flag = true;
      for (let i = 0; i < this.dataList.length; i++) {
        if (!this.checkFunc(this.dataList[i])) {
          flag = false;
          break;
        }
      }
      if (!flag) {
        return;
      }
      if (!this.agress) {
        this.$EventBus.$emit("toast", {
          type: "error",
          message: "请先同意相关协议",
        });
        return;
      }
      this.sendRepairApplication();
    },
    checkFunc(item) {
      if (item.type == 2) {
        let list = [...item.uploadList, ...item.list];
        if (list.length == 0) {
          this.$EventBus.$emit("toast", {
            type: "error",
            message: `请先上传${item.name}`,
          });
          return false;
        }
        return true;
      } else if (item.fieldName == 'address') {
        if (this.warrantyId) {
          if (!this.notModifyAddress && !this.isNull(this.selectAddress) && this.isNull(this.detailAddress)) {
            this.isShow = true
            return false
          }
          if (!this.isNull(this.selectAddress) && !this.isNull(this.detailAddress)) {
            item.value = this.selectAddress + this.detailAddress
            return true
          }
          if (this.isNull(item.value)) {
            this.$EventBus.$emit("toast", {
              type: "error",
              message: `请选择地址`,
            });
            return false
          }
          return true
        } else {
          if (this.isNull(this.selectAddress)) {
            this.$EventBus.$emit("toast", {
              type: "error",
              message: `请选择地址`,
            });
            return false
          }
          if (this.isNull(this.detailAddress)) {
            this.$EventBus.$emit("toast", {
              type: "error",
              message: `请填写详细地址`,
            });
            return false
          }
          item.value = this.selectAddress + this.detailAddress
          return true
        }
      } else {
        if (this.isNull(item.value)) {
          this.$EventBus.$emit("toast", {
            type: "error",
            message: `请先填写${item.name}`,
          });
          return false;
        }
        return true;
      }
    },

    // 图片数据处理
    dealPicture(data) {
      let list = [];
      data.list.forEach((item) => {
        list.push(this.getQinniuUrl + "/" + item.key);
      });

      return [...data.uploadList, ...list];
    },
    /**
     * @description: 数据提交
     * @return {*}
     */
    sendRepairApplication() {
      console.log(this.dataList)

      if (this.loading) {
        this.$EventBus.$emit("toast", { type: "error", message: '数据提交中，请稍后' });
        return
      }
      this.loading = true
      let data = {};
      let custom = {};
      this.dataList.forEach((item) => {
        if (item.isCustom) {
          if (item.fieldName == 'goodsName') {
            let index = this.productList.findIndex(product => {
              return product.goodsName === item.value || product.goodsNo === item.value
            })
            console.log(item.value)
            console.log(index)
            console.log(this.productList[index])
            if (index > -1) {
              custom.goodsName = this.productList[index].goodsName
              custom.goodsNo = this.productList[index].goodsNo
              custom.goodsBarcode = this.productList[index].goodsBarcode
            }
            return
          }
          custom[item.fieldName] =
            item.type == 2 ? this.dealPicture(item) : item.value;
        } else {
          data[item.fieldName] =
            item.type == 2 ? this.dealPicture(item) : item.fieldName == 'mailingList' ? item.value.join(",") : item.value;
        }
      });
      data.custom = JSON.stringify(custom);
      this.warrantyId ? (data.warrantyId = this.warrantyId) : "";

      // console.log("最后的数据", data)

      this.$request({
        method: this.warrantyId ? "PUT" : "post",
        url: this.$requestPath[
          this.warrantyId ? "updRepairApplication" : "sendRepairApplication"
        ],
        data,
      })
        .then((res) => {
          // console.log("保修申请", res);
          if (res.code == 0) {
            this.$EventBus.$emit("toast", {
              type: "success",
              message: "提交成功",
            });
            setTimeout(() => {
              this.loading = false
              if (this.warrantyId) {
                this.getDetail()
              } else {
                this.dataList.forEach((item) => {
                  item.value = "";
                  item.list = [];
                  if (item.type == 2) {
                    this.$refs[item.fieldName][0].clearFiles();
                  }
                  // console.log(item);
                });
                this.$router.push({ path: "/index/warranty/qurey" })
              }
            }, 500);
          } else {
            this.loading = false
            this.$EventBus.$emit("toast", { type: "error", message: res.msg });
          }
        })
        .catch((error) => {
          this.loading = false
          console.log("保修申请", error);
        }).finally(() => {
          console.log('finally')
          this.loading = false;
        })
    },
    // 跳转到协议页面
    goAgreement(index) {
      this.$router.push({
        path: "/index/agreement",
        query: {
          code: this.agreement[index],
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table_title {
  background: #e8e8e8;
}

.li_item {
  width: 20%;
}

@media screen and (max-width: 640px) {
  .serve_btn {
    width: 7.5rem;
  }

  .input_box {
    flex: 1;
  }

  .pc_show {
    display: none;
  }

  .phone_show {
    display: flex;
  }

  .drive_title_box {
    margin: 0.625rem 0px;

    img {
      width: 1.4375rem;
    }

    .drive_title {
      font-size: 1rem;
      margin-left: 0.375rem;
      display: flex;
      padding-top: 0.0625rem;
    }
  }
}

@media screen and (min-width: 640px) {
  .serve_btn {
    width: 9.375rem;
  }

  .input_box {
    width: 350px;
  }

  .pc_show {
    display: flex;
  }

  .phone_show {
    display: none;
  }

  .drive_title_box {

    // margin: 0.625rem 0px;
    img {
      width: 1.25rem;
    }

    .drive_title {
      font-size: 0.9375rem;
      margin-left: 0.375rem;
      display: flex;
      padding-top: 0.0625rem;
    }
  }
}
</style>
